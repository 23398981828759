import React from 'react';
import ReactDOM from 'react-dom/client';
import loadable from '@loadable/component';
import { FormType } from './common/components/QuickQuoteForm/types';

// new profile page CRO
const backToListingButton = document.querySelector('.back-to-listing') as HTMLElement;
const divider = document.querySelector('.space-breadcrumbs-outer-panel .divider') as HTMLElement;
// @ts-ignore
if (backToListingButton) {
  if (sessionStorage.getItem('isShowBackButton') === 'true') {
    backToListingButton.style.display = 'flex';
    divider.style.display = 'block';
    backToListingButton.addEventListener('click', () => {
      const lastUrl = sessionStorage.getItem('lastUrl');
      sessionStorage.setItem('isShowBackButton', 'false');
      if (lastUrl) {
        sessionStorage.setItem('useLastRequest', 'true');
        window.location.href = lastUrl;
      }
    });
  } else {
    backToListingButton.style.display = 'none';
    divider.style.display = 'none';
  }
}

// my_account
const loginButton = document.querySelectorAll('[data-target="#login-form-popup"]');
loginButton.forEach((button) => button?.addEventListener('click', (e) => {
  e.preventDefault();
  e.stopPropagation();
  document.querySelector('[class*="LoginPopupMain_background"]')?.setAttribute('style', 'display: block');
  document.body.style.overflow = 'hidden';
  document.body.style.paddingRight = '15px';
}));

const anotherLoginButton = document.querySelector('#already-login-popup');
anotherLoginButton?.addEventListener('click', (e) => {
  e.preventDefault();
  e.stopPropagation();
  document.querySelector('[class*="LoginPopupMain_background"]')?.setAttribute('style', 'display: block');
  document.body.style.overflow = 'hidden';
  document.body.style.paddingRight = '15px';
});

if (process.env.REACT_APP_BUILD_TARGET === 'dev-server') {
  import('./index.css');
}

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

// eslint-disable-next-line
// @ts-ignore
if (window['REVIEWS_EXPERIMENT_ENABLED'] || typeof( params.is_abtest ) != 'undefined') {

  const removeReviewStars = () => {
    // if we are running experiment remove all reviews from the list items
    const reviews = document.querySelectorAll('.search-review-holder');
    reviews.forEach((el: HTMLElement) => {
      el.remove();
    });
  };

  const isCenterPage: boolean = !!document.querySelector('.space-right-buttons-outer');

  if (!isCenterPage) {
    removeReviewStars();
    // todo remove this hack
    // eslint-disable-next-line
    // @ts-ignore
    window['removeReviewsAsync'] = () => {
      removeReviewStars();
    };
  }
}

const isCenterPage: boolean = !!document.querySelector('.space-right-buttons-outer');

let isAppLoaded = false;
const loadApp = () => {
  if (isAppLoaded) {
    return;
  }

  const ReactApp = loadable(() => import('./ReactApp'));

  const root = ReactDOM.createRoot(
    document.getElementById('reactRoot') as HTMLElement,
  );
  root.render(<ReactApp />);

  isAppLoaded = true;
};

const observerOptions = {
  threshold: 1.0,
};
const observer = new IntersectionObserver((entries) => {
  for (let i = 0; i < entries.length; i++) {
    if (entries[i].isIntersecting) {
      loadApp();
      observer.disconnect();
      break;
    }
  }
}, observerOptions);

const replacingButtons = document.querySelectorAll('.quickQuoteButton, .bookViewingButton, .quickQuoteButtonGeneral');
if (replacingButtons?.length) {
  replacingButtons.forEach((button) => observer.observe(button));

  const openModal = async (formType: FormType, targetElement: HTMLElement): Promise<void> => {
    let spaceId;
    if (isCenterPage) {
      spaceId = (document.getElementById('space_id') as HTMLInputElement)?.value;
    } else {
      const parentElement = targetElement?.parentElement;

      spaceId = parentElement?.dataset?.spaceId;
    }
    const modalProperties = {
      formType,
      spaceId,
      targetElement,
    };

    const globalAny: any = global;
    globalAny.toggleReactModal({ isQuickQuoteModalOpen: true }, modalProperties);
  };

  const assignEventListeners = () => {
    const quickQuoteButtons: NodeList = document.querySelectorAll('.quickQuoteButton');
    const bookViewingButtons: NodeList = document.querySelectorAll('.bookViewingButton');
    const quickQuoteButtonsGeneral: NodeList = document.querySelectorAll('.quickQuoteButtonGeneral');
  
    quickQuoteButtons.forEach((button: Node) => {
      (button as HTMLElement).setAttribute('data-name', 'booking-enquiry-submit');
      button?.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        openModal(FormType.QuickQuote, e.target as HTMLElement);
      });
    });
    bookViewingButtons.forEach((button: Node) => {
      button?.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        openModal(FormType.BookViewing, e.target as HTMLElement);
      });
    });
    quickQuoteButtonsGeneral.forEach((button: Node) => {
      (button as HTMLElement).setAttribute('data-name', 'schedule-a-tour-success');
      button?.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        openModal(FormType.GeneralEnquiry, e.target as HTMLElement);
      });
    });
  };

  assignEventListeners();

  if (!isCenterPage) {
    // todo remove this hack
    // eslint-disable-next-line
    // @ts-ignore
    window['assignReactEventListeners'] = () => {
      // @ts-ignore
      window.instantiateListItemSlider();
      assignEventListeners();
    };
  }
}

loadApp();
