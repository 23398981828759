import { ReactNode } from 'react';
import { ShortListItem } from '../../types/office';
import { CountryItem } from '../CountrySelect/types';

export enum FormType {
  QuickQuote,
  BookViewing,
  LargeTeams,
  GeneralEnquiry,
}

export enum OfficeOptionKey {
  Desks = 1,
  PrivateOffice,
  NotSure,
  ServicedOffices,
  LeasedOffice,
  ManagedOffice,
  CoworkingDesksDedicated,
  CoworkingDesksMembership,
}

interface BaseStepOption {
  text: string;
  value: string;
}

interface BaseRadioGroupStep<T extends BaseStepOption> {
  options: T[];
}

export interface DateTimeStep {
  dateFieldLabel: string;
  timeFieldLabel: string;
  userTime?: string | null;
  languageCode?: string;
  requiredLabel: string;
  disabledDays?: string[] | null;
}

export interface AvailabilityStep {
  waitingLabel?: string;
}

export interface LoadingStep {
  text?: string;
}

export interface BestPriceStep {
  text?: string;
}

export interface PeopleStepOption {
  text: string;
  value?: string;
  minValue?: number;
  maxValue?: number;
  numericValidationLabel?: string;
  minValueValidationLabel?: string;
  maxValueValidationLabel?: string;
}

export interface PeopleStep {
  options: PeopleStepOption[];
  customOptions: PeopleStepOption[];
  customFieldLabel: string;
  requiredLabel: string;
}

export interface OfficeTypeOption extends BaseStepOption {
  key?: OfficeOptionKey;
  singleVersionTitle?: string;
  singleVersionValue?: string;
  imageURL?: string;
  description?: string;
}

export interface OfficeTypeStep extends BaseRadioGroupStep<OfficeTypeOption> {
  mobileTooltipTitle: string;
}

export interface OfficeOptionsOption extends BaseStepOption {
  key?: OfficeOptionKey;
  singleVersionTitle?: string;
  singleVersionValue?: string;
  description: string;
}

export interface OfficeOptionsStep {
  deskTitle?: string;
  singleVersionDeskTitle?: string;
  desksOptions: OfficeOptionsOption[];
  officesOptions: OfficeOptionsOption[];
}

export type CustomFittedStepOption = BaseStepOption;
export type CustomFittedStep = BaseRadioGroupStep<CustomFittedStepOption>;

export type RentPeriodOption = BaseStepOption;
export type RentPeriodStep = BaseRadioGroupStep<RentPeriodOption>;

interface EmailStepHint {
  title: string;
  text: string;
}

export interface EmailStep {
  emailFieldCTA: string;
  emailFieldCTAExperimental: string;
  emailFieldValue?: string;
  emailFieldLabel: string
  emailFieldMaxLength: number;
  incorrectEmailLabel: string;
  requiredLabel: string;
  maxLengthLabel: string;
  hint: EmailStepHint;
  firstNameFieldLabel: string;
  phoneValidationLabel: string;
  specialCharactersLabel: string;
  fieldMaxLength: number;
}

export interface PhoneExperimentalStep {
  phoneFieldLabel: string;
  phoneFieldValue?: string;
  requiredLabel: string;
  phoneValidationLabel: string;
  chooseTheCountryLabel: string;
  searchForCountriesLabel: string;
  noResultsFound: string;
}

export interface ContactDataExperimentalStep {
  firstNameFieldLabel: string;
  firstNameFieldValue?: string;
  lastNameFieldLabel: string;
  lastNameFieldValue?: string;
  phoneFieldLabel: string;
  phoneFieldValue?: string;
  companyFieldLabel: string;
  companyFieldValue?: string;
  requiredLabel: string;
  invalidEntryLabel: string;
  fieldMaxLength: number;
  maxLengthLabel: string;
  phoneValidationLabel: string;
  specialCharactersLabel: string;
  chooseTheCountryLabel: string;
  searchForCountriesLabel: string;
  noResultsFound: string;
  dateFieldLabel: string;
  disabledDays?: string[] | null;
  userTime?: string | null;
  languageCode?: string;
  optionalLabel?: string;
  captchaSiteKey: string;
}

export interface ContactDataStep {
  title?: string;
  emailFieldLabel: string;
  incorrectEmailLabel: string;
  hint: EmailStepHint;
  firstNameFieldLabel: string;
  firstNameFieldValue?: string;
  lastNameFieldLabel: string;
  lastNameFieldValue?: string;
  companyFieldLabel: string;
  companyFieldValue?: string;
  phoneFieldLabel: string;
  phoneFieldValue?: string;
  requiredLabel: string;
  invalidEntryLabel: string;
  fieldMaxLength: number;
  maxLengthLabel: string;
  phoneValidationLabel: string;
  specialCharactersLabel: string;
  chooseTheCountryLabel: string;
  searchForCountriesLabel: string;
  noResultsFound: string;
  optionalLabel?: string;
  captchaSiteKey: string;
}

export interface AbandonmentStep {
  askingQuestionsLabel: string;
  stayLabel: string;
  wantToLeaveLabel: string;
  yesLeaveLabel: string;
}

export interface SummaryStep {
  successMessage: string;
  inviteMessage: string;
  infoMessage: string;
  spaceName: string;
  facebook: string;
  twitter: string;
  instagram: string;
  loginMessage: string;
}

export interface QuickQuoteInitialData {
  completeLabel: string;
  continueButtonLabel: string;
  goBackButtonLabel: string;
  bookViewingStepCTALabel: string;
  quickQuoteStepCTALabel: string;
  bookAViewingLabel: string;
  quickQuoteLabel: string;

  dateTimeStep?: DateTimeStep;
  peopleStep: PeopleStep;
  officeTypeStep: OfficeTypeStep;
  officeOptionsStep: OfficeOptionsStep;
  customFittedStep?: CustomFittedStep;
  rentPeriodStep: RentPeriodStep;
  availabilityStep: AvailabilityStep;
  loadingStep: LoadingStep;
  bestPriceStep: BestPriceStep;
  emailStep: EmailStep;
  contactDataStep: ContactDataStep;
  phoneExperimentalStep: PhoneExperimentalStep;
  cartAbandonmentStep: AbandonmentStep;
  summaryStep: SummaryStep;

  getPriceButtonLabel: string;
  communicationTermsText: string;
  communicationTermsLinkLabel: string;
  globalTermsText: string,
  globalTermsLink: string,
  globalTermsLinkLabel: string,
  privacyPolicyTextStart: string,
  privacyPolicyTextEnd: string,
  privacyPolicyLink: string,
  privacyPolicyLinkLabel: string,
}

export interface CookieInfo {
  cookieAcceptButton: string;
  cookieSettingsButton: string;
  cookieHeader: string;
  cookieContent: string;

  cookieGeneralInfo: string;
  cookieStrictlyNecessary: string;
  cookiePerformanceTargeting: string;
  cookieFunctional: string;
  cookie3rdParty: string;

  isHidden?: boolean;
  allowedCookies?: any;
  setAllowedCookies?: any;
  closeSettings?: any;
  onAccept?: () => void;
  onCookieHide?: (ifAcceptAll?:boolean) => void;
}

export interface QuickQuoteFormProps {
  shortlistItem?: ShortListItem;
  formType?: FormType;
  initialData: QuickQuoteInitialData;
  goBackCallback?: () => void;
  dataNamePrefix?: string;
  isModal?: boolean;
  isClosureTriggered?: boolean;
  closeCallback: () => void;
  cancelClosureCallback: () => void;
  updateTrustSignalsCallback?: (isNeeded: boolean) => void;
}

export interface QuickQuoteFormData {
  preferredDate?: string;
  preferredHour?: string;
  people: string;
  officeType?: OfficeTypeOption;
  officeOptions?: OfficeOptionsOption;
  customFitted?: string;
  rentPeriod: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  phoneCodeCountry?: CountryItem;
  captchaToken: string;
}

export interface SpaceDataDto {
  NAME: string;
  add_amenities?: string;
  additional_images: string;
  address: string;
  amenities?: any;
  bdp_price: string;
  bussiness_week: string;
  capacity: string;
  city: string;
  continent: string;
  country: string;
  cs_id: string;
  currency: string;
  cw_url: string;
  d_price: string;
  day_price: string;
  deposit: string;
  description: string;
  desks: any[];
  email: string;
  ena_reservation: string;
  fri: string;
  gmt_hours: string;
  holiday_name: any[];
  hour_price: null
  image: string;
  is_buydaypass_enabled: string;
  is_closed: string;
  is_favorite: string;
  is_full: string;
  is_working: number;
  latitude: string;
  locale_code: string;
  longitude: string;
  meeting_rooms: any[];
  mon: string;
  month_price: string;
  notification_emails: string;
  num_desks: string;
  num_meeting_rooms: string;
  num_private_rooms: string;
  num_reviews: string;
  odp: string;
  opt_global_pass: string;
  phone: string;
  private_rooms: any[];
  reserved_time_slots: any[];
  sat?: any;
  sched_daypass: any[];
  sched_tour: any[];
  short_description: string;
  star_rating?: any;
  state_name?: any;
  sun?: any;
  thu: string;
  timezone_name: string;
  tue: string;
  url: string;
  wed: string;
  week_price: string;
  zone_name: string;
  facebook: string;
  twitter: string;
  instagram: string;
}

export type LeadTypeCode = 'resource_availability' | 'tour' | 'general_enquiry';

export interface SubmitFormDto {
  query_string: string,
  lead_type_code: LeadTypeCode;
  capture_page: string;
  space_id: number;
  user_first_name: string;
  user_last_name: string;
  user_last_name_: string;
  user_name: string;
  user_email: string;
  user_phone: string;
  user_phone_country_code: string;
  user_message: string;
  user_country_code: string;
  user_city: string;
  user_region: string;
  user_ip: string;
  user_lat: string;
  user_lng: string;
  start_date: string;
  resource_type_code: string;
  duration_metric: string;
  duration_qty: number
  capacity: number;
  captcha_token: string;
  user_company?: string;
  user_phone_country_id?: string;
  tour_start_date?: string;
  time_slot?: string;
  city_id: number;
  country_id: number;
}

export interface StepContainerProps {
  progress?: number;
  title?: string;
  stepCTALabel?: string;
  completeLabel: string;
  goBackButtonLabel?: string;
  goBackHandler?: () => void;
  continueButtonLabel: string;
  continueHandler: (isSkipped?: boolean) => void;
  isContinueButtonDisabled?: boolean;
  isContinueButtonAwaiting?: boolean;
  children?: ReactNode;
  shouldDisplayBackButton?: boolean;
  isModal?: boolean;
  isLastStep?: boolean;
}

export interface QuickQuoteComponentStepProps extends StepContainerProps {
  formData: QuickQuoteFormData;
  updateFormData: (formFields: Partial<QuickQuoteFormData>) => void;
  dataNamePrefix?: string;
}
